








































import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import { MessageService } from "@/shared/services/message-service";
import { etiqueta } from "./etiqueta";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { centro_trabajo } from "@/shared/dtos/centro_trabajo";
@Component({
  components: {},
})
export default class EtiquetasCombo extends Vue {
  @PropSync("modeloetiquetas", { default: [] }) model!: any;
  @PropSync("propnombre_campo", { default: [] }) nombre_campo!: any;

  public async created() {}
  public activator = null;
  public attach = null;
  public editing = null;
  public index = -1;
  public menu = false;
  public search = null;

  public get Etiquetas() {
    return this.model;
  }

  @Watch("model")
  public Wmodel(val: any, prev: any) {
    try {
      if (val.length === prev.length) return;
    } catch (error) {
      return;
    }

    this.model = val.map((v: any) => {
      if (typeof v === "string") {
        let eti: etiqueta = new etiqueta();
        eti.nombre = v;
        eti.sistema = false;
        eti.id_tipoetiqueta = 2;
        /*etiquetaModule.guardaretiqueta(eti);
        etiquetaModule.AddCaheEtiqueta({
          text: v,
          sistema: false,
        });*/
        this.model.push({
          nombre: v,
        });
        //Insert Api
        v = {
          nombre: v,
        };
        MessageService.toast("Etiqueta creada");
      }

      return v;
    });
  }

  public filter(item: any, queryText: string, itemText: any) {
    if (item.header) return false;

    const hasValue = (val: any) => (val != null ? val : "");

    const text = hasValue(itemText);
    const query = hasValue(queryText);

    return (
      text
        .toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    );
  }
}
